// Смена PublicPath в WebPack
// __webpack_public_path__ = 'assets_path/js/';

import svg4everybody from "svg4everybody";
import ready from "./tools/dom/ready";
import Toggle from '../components/toggle/toggle';
import Header from '../components/header/header';
import OnTop from '../components/on-top/on-top';
import Quantity from '../components/quantity/quantity';
import 'bootstrap';

// Import Components

// Factories class based plugins
// Example
// Accordion(".accordion");
Header('[data-page="header"]');
Toggle('[data-page-toggle]');
OnTop('[data-page="scroll-top"]')
Quantity('[data-page="quantity"]');
// Simple functional plugins
// Example
// Demo();

$(function() {
  $('[data-page="slider"]').each((el, r)  => {
    $(r).slick();
  });

  $(document).on("mouseup",function (e) {
    var container = $('[data-page="search-modal"]');
    if (container.has(e.target).length === 0){
      if (container.hasClass('show'))
        container.find('.search-modal__close').trigger('click');
    }
  });
});

// DOMContentLoaded
ready(function() {
    svg4everybody();
});

export {

}
