import Plugin from "@/js/core/Plugin";
import init from "@/js/core/init";

class Toggle extends Plugin {
  defaults() {
    return {};
  }

  init() {}

  buildCache() {}

  bindEvents() {
    this.element.addEventListener('click', this.toggle.bind(this));
  }

  toggle(e) {
    e.preventDefault();
    const selector = this.element.getAttribute('data-page-toggle');
    const el = document.querySelector(selector);
    const html = document.querySelector('html');

    if (el) {
      const overflow = el.getAttribute('data-page-overflow');

      if (el.classList.contains('show')) {
        el.classList.remove('show');
        if (overflow) {
          html.classList.remove('fixed');
        }

      } else {
        el.classList.add('show');
        if (overflow) {
          html.classList.add('fixed');
        }
      }
    }
  }
}

export default init(Toggle, "toggle");
