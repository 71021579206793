import Plugin from "@/js/core/Plugin";
import init from "@/js/core/init";
import scrollTo from "@/js/tools/scrollTo";

class OnTop extends Plugin {
  defaults() {
    return {};
  }

  init() {}

  buildCache() {}

  bindEvents() {
    window.addEventListener('scroll', this.docScrolled.bind(this));
    this.element.addEventListener('click', this.scrollTop.bind(this));
  }

  scrollTop(e) {
    e.preventDefault();
    let _options = {
      to: 0,
      options: {
        duration: 500
      }
    }
    scrollTo(_options);
  }

  docScrolled(e) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop >= 50) {
      this.element.classList.add('button-show');
    } else {
      this.element.classList.remove('button-show');
    }
  }
}

export default init(OnTop, "on-top");
