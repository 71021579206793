import Plugin from "@/js/core/Plugin";
import init from "@/js/core/init";

class Header extends Plugin {
  defaults() {
    return {};
  }

  init() {}

  buildCache() {
    this.pos = this.element.parentNode.offsetTop;
  }

  bindEvents() {
    window.addEventListener('scroll', this.docScrolled.bind(this));
  }

  docScrolled(e) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop >= this.pos) {
      this.element.parentNode.style.height = this.element.clientHeight + 'px';
      this.element.classList.add('fixed');
    } else {
      this.element.parentNode.style.height = '';
      this.element.classList.remove('fixed');
    }
  }
}

export default init(Header, "header");
