import Plugin from "@/js/core/Plugin";
import init from "@/js/core/init";

class Quantity extends Plugin {
  defaults() {
    return {};
  }

  init() {}

  buildCache() {
    this.input = this.element.querySelector('[type="number"]');
    this.plus = this.element.querySelector('.plus');
    this.minus = this.element.querySelector('.minus');
    this.value = Number(this.input.getAttribute('value')) | 1;
    this.step = Number(this.input.getAttribute('step')) | 1;
    this.min = Number(this.input.getAttribute('min')) | 1;
    this.max = Number(this.input.getAttribute('max'));
  }

  bindEvents() {
    this.plus.addEventListener('click', this.add.bind(this));
    this.minus.addEventListener('click', this.sub.bind(this));
    this.input.addEventListener('change', this.change.bind(this));
  }

  add() {
    if (this.value < this.max) {
      this.value += this.step;
    } else if (this.max === 0) {
      this.value += this.step;
    }
    this.update();
  }

  sub() {
    if (this.value - this.step >= this.min) {
      this.value -= this.step;
    }
    this.update();
  }

  change(e) {
    const newVal = Number(e.target.value);
    if (this.max === 0) {
      if (newVal <= this.min) {
        this.value = this.min;
      } else {
        this.value = newVal;
      }
    }else {
      if ((newVal <= this.max) && (newVal >= this.min)) {
        this.value = newVal;
      } else if (newVal >= this.max) {
        this.value = this.max;
      } else if (newVal <= this.min) {
        this.value = this.min;
      }
    }
    this.update();
  }

  update() {
    this.input.value = this.value;
  }
}

export default init(Quantity, "quantity");
